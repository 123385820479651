import React from 'react'
import styled from 'styled-components'
import { Switch as MaterialSwitch } from '@material-ui/core'

const Switch = ({ defaultChecked, onChange, checked }) => {
	const DefaultSwitch = styled(MaterialSwitch)(() => ({
		'& .MuiSwitch-switchBase.Mui-checked': {
			color: '#0685C5'
		},
		'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
			backgroundColor: '#0685C5'
		}
	}))

	return <DefaultSwitch onChange={onChange} checked={checked} defaultChecked={defaultChecked} />
}

export default Switch
