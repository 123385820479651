const ECU = {
	colors: {
		primary: `#0685C5`,
		secondary: `#B0BEC5`,
		tertiary: `#656565`,
		background: `#FFF`,
		text: `#333333`
	},
	fonts: {
		big: 'Poppins-bold',
		medium: 'Poppins'
	},
	sizes: {
		maxDesktopLarge: '1200px',
		maxDesktop: '1024px',
		maxTablet: '768px',
		maxMobile: '480px'
	}
}

export default ECU
