import styled from 'styled-components'
import UniversalLink from '../UniversalLink/UniversalLink'

const MobileKeyPopupContainer = styled.div`
	height: ${props => (props.open ? '480px' : '0')};
	width: 90%;
	max-width: 100%;
	position: fixed;
	bottom: 0;
	transition: all 0.5s ease-out;
	margin: 0 auto;

	border-radius: 5px;
	flex-direction: column;
	z-index: 9997;
	background-image: url('https://cms.ecuadaptions.nl/wp-content/uploads/2021/06/FG4A0814-bewerkt-bewerkt.jpg');
	background-size: cover;
	background-position-x: center;

	&::before {
		content: ' ';
		width: 100%;
		height: 100%;
		background-color: #000;
		opacity: 0.5;
		position: absolute;
		z-index: 9998;
	}
`
const DesktopPopupContainer = styled.div`
	height: ${props => (props.open ? '450px' : '0')};
	width: 410px;
	max-width: 100%;
	position: fixed;
	bottom: 0;
	right: 0;
	transition: all 0.5s ease-out;

	border-radius: 5px;
	flex-direction: column;
	align-items: center;
	z-index: 9997;
	background-image: url('https://cms.ecuadaptions.nl/wp-content/uploads/2021/06/FG4A0814-bewerkt-bewerkt.jpg');
	background-size: cover;
	background-position-x: center;

	&::before {
		content: ' ';
		width: 100%;
		height: 100%;
		background-color: #000;
		opacity: 0.5;
		position: absolute;
		z-index: 9998;
	}
`

const ContentContainer = styled.div`
	padding: 40px 40px 80px 40px;
	z-index: 9999;
	position: relative;

	height: ${props => (props.open ? '200px' : '0')};
`

const MobileContentContainer = styled.div`
	padding: 5px 30px 30px 30px;
	z-index: 9999;
	position: relative;

	height: 250px;
`

const PopupFooterText = styled.p`
	color: #fff;
	font-weight: 900;
`

const PopupText = styled.p`
	color: #fff;
	margin-bottom: 30px;
`

const PopupTitle = styled.h2`
	color: #fff;
	font-style: capitalize;
	margin-top: ${props => (props.mobile ? '40px' : '')};
	font-size: ${props => (props.mobile ? '24px' : '')};
`

const CloseButton = styled.div`
	position: absolute;
	right: 20px;
	top: 20px;
	cursor: pointer;

	&:hover {
		i {
			cursor: pointer;
			color: grey;
		}
	}
`

export {
	MobileKeyPopupContainer,
	DesktopPopupContainer,
	ContentContainer,
	MobileContentContainer,
	PopupFooterText,
	PopupText,
	PopupTitle,
	CloseButton
}
