import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { LinkOrButtonWrapper } from '../LinkOrButton/LinkOrButton.styled'

const Icon = styled(FontAwesomeIcon)`
	position: relative;
	color: ${props => (props.color === 'primary' ? props.theme.colors.primary : props.color)};
	width: ${props => props.width} !important;
	height: ${props => props.height} !important;
	margin: ${props => props.margin};
	transition: ease 0.2s;

	${LinkOrButtonWrapper}:hover & {
		margin-left: 20px;
	}
`
export default Icon
