import { useStaticQuery, graphql } from 'gatsby'

export const useOptionsPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				optionsPage {
					acf {
						top_header_items {
							page {
								page_url
								title
							}
						}
						top_header_phone_number
						logo {
							localFile {
								childImageSharp {
									gatsbyImageData(height: 100)
								}
							}
						}
						footerColumns {
							title
							content
						}
					}
				}
			}
		`
	)
	return data.optionsPage.acf
}
