import styled from 'styled-components'
import UniversalLink from '../UniversalLink/UniversalLink'

const RedirectButton = styled(UniversalLink)`
	font-family: ${props => props.theme.fonts.medium};
	color: ${props => (props.variant === 'primary' || props.variant === 'tertiary' ? '#fff' : '#333')};
	background-color: ${props =>
		!props.disabled
			? props.variant === 'primary'
				? props.theme.colors.primary
				: props.variant === 'secondary'
				? props.theme.colors.secondary
				: props.variant === 'tertiary'
				? props.theme.colors.tertiary
				: '#333'
			: '#c3c3c3'};
	border-radius: 5px;
	padding: ${props => (props.padding ? props.padding : '12px 20px')};
	text-align: center;
	margin: ${props => (props.margin ? props.margin : 0)};
	cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
	pointer-events: ${props => (props.disabled ? 'none' : 'null')};

	&:hover {
		background-color: ${props =>
			!props.disabled
				? props.variant === 'primary'
					? '#109be0'
					: props.variant === 'secondary'
					? '#cbd9e0'
					: props.variant === 'tertiary'
					? '#898989'
					: '#333'
				: '#c3c3c3'};
	}
`

const OnClickButton = styled.button`
	font-family: ${props => props.theme.fonts.medium};
	color: ${props =>
		!props.disabled ? (props.variant === 'primary' || props.variant === 'tertiary' ? '#fff' : '#333') : '#808080'};
	color: ${props => (props.variant === 'primary' || props.variant === 'tertiary' ? '#fff' : '#333')};
	background-color: ${props =>
		!props.disabled
			? props.variant === 'primary'
				? props.theme.colors.primary
				: props.variant === 'secondary'
				? props.theme.colors.secondary
				: props.variant === 'tertiary'
				? props.theme.colors.tertiary
				: '#333'
			: '#c3c3c3'};
	border-radius: 5px;
	padding: ${props => (props.padding ? props.padding : '12px 20px')};
	border: none;
	text-align: center;
	margin: ${props => (props.margin ? props.margin : 0)};
	cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
	width: 100;

	&:hover {
		background-color: ${props =>
			!props.disabled
				? props.variant === 'primary'
					? '#109be0'
					: props.variant === 'secondary'
					? '#cbd9e0'
					: props.variant === 'tertiary'
					? '#898989'
					: '#333'
				: '#c3c3c3'};
	}
`

export { RedirectButton, OnClickButton }
