import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import CookieConsent from 'react-cookie-consent'
import Cookies from 'universal-cookie'

const CookieConsentV2 = ({ openModal, allDeclined, cookiePreferences }) => {
	const [barVisible, setBarVisible] = useState(true)

	const cookies = new Cookies(null, {
		path: '/'
	})

	useEffect(() => {
		const cookiesSet = cookies.get('cookie-consent') !== undefined
		const cookieConsent = cookies.get('cookie-consent')

		if (cookiesSet) {
			setCookieConsent(cookieConsent)
			setBarVisible(false)
			return
		}
	})

	useEffect(() => {
		if (!allDeclined) {
			return
		}

		cookies.set('cookie-consent', 'false')
		setBarVisible(false)
	}, [allDeclined])

	const setCookieConsent = consent => {
		setBarVisible(false)

		cookies.set('cookie-consent', consent)

		window.dataLayer.push({
			event: 'accept-consent',
			...consent
		})
	}

	useEffect(() => {
		let consent = {
			ad_storage: 'denied',
			analytics_storage: 'denied',
			ad_user_data: 'denied',
			ad_personalization: 'denied',
			security_storage: 'granted',
			functionality_storage: 'denied',
			personalization_storage: 'denied'
		}

		if (cookiePreferences.length) {
			cookiePreferences.find(pref => {
				if (pref === 'NECESSARY') {
					consent.functionality_storage = 'granted'
				}
				if (pref === 'ADS') {
					consent.ad_personalization = 'granted'
					consent.ad_user_data = 'granted'
					consent.ad_storage = 'granted'
					consent.personalization_storage = 'granted'
				}

				if (pref === 'STATISTICS') {
					consent.analytics_storage = 'granted'
				}
			})

			setBarVisible(false)
			setCookieConsent(consent)
		}
	}, [cookiePreferences])

	return (
		<CookieConsent
			location="bottom"
			buttonText="Accepteer"
			cookieName="cookie-consent"
			style={{ background: '#656565', zIndex: 9999, height: 'fit-content', display: 'flex', alignItems: 'center' }}
			onAccept={() => {
				setCookieConsent({
					functionality_storage: 'granted',
					personalization_storage: 'granted',
					security_storage: 'granted',
					ad_storage: 'granted',
					analytics_storage: 'granted',
					ad_user_data: 'granted',
					ad_personalization: 'granted'
				})
			}}
			visible={barVisible ? 'show' : 'hidden'}
			buttonStyle={{
				backgroundColor: '#0686c5',
				color: '#fff',
				fontSize: '16px',
				borderRadius: '5px',
				padding: '12px 20px',
				marginRight: '20px',
				margin: '-15px 30px 15px'
			}}
			expires={150}
		>
			<CookieBarContent>
				<CookieBarTitle>Jouw privacy voorkeuren </CookieBarTitle>
				<CookieBarText>
					Deze website maakt gebruik van cookies om jou de best mogelijke ervaring te bieden. Door deze site te
					gebruiken, ga je akkoord met het gebruik van cookies in overeenstemming met ons CookieBeleid. Je kunt je
					cookievoorkeuren op elk moment aanpassen via de onderstaande link. Klik op &apos;Accepteren&apos; om akkoord
					te gaan met ons gebruik van cookies en door te gaan naar de site{' '}
					<ChangePreferencesLink onClick={() => openModal(true)}>pas uw voorkeuren hier aan.</ChangePreferencesLink>
				</CookieBarText>
			</CookieBarContent>
		</CookieConsent>
	)
}
export default CookieConsentV2

const CookieBarContent = styled.div`
	padding: 20px;
`

const CookieBarTitle = styled.span`
	font-weight: bold;
	margin-bottom: 5px;
	display: block;
	font-size: 20px;
`

const CookieBarText = styled.p`
	font-size: 13px;
	margin-bottom: 0;
`

const ChangePreferencesLink = styled.span`
	color: #30a2db;
	cursor: pointer;
`
