import React from 'react'
import styled from 'styled-components'
import { useOptionsPage } from '../../hooks/useOptionsPage'
import HTMLContent from '../HTMLContent'
import BottomBar from './BottomBar'

const Footer = () => {
	const { footerColumns } = useOptionsPage()

	return (
		<>
			<FooterContainer>
				{footerColumns &&
					footerColumns.map((col, index) => (
						<FooterCol key={index}>
							<FooterTitle>{col.title}</FooterTitle>
							<FooterText
								dangerouslySetInnerHTML={{
									__html: col.content
								}}
							/>
						</FooterCol>
					))}
			</FooterContainer>
			<BottomBar />
		</>
	)
}

const FooterContainer = styled.footer`
	width: 100%;
	padding: 1rem;
	display: flex;
	flex-direction: row;
	background-color: #818181;
	justify-content: space-evenly;
	color: white;
	flex-wrap: wrap;
`
const FooterCol = styled.div`
	padding: 1rem;
	max-width: 25rem;
	min-width: 17rem;
`

const FooterTitle = styled.h4`
	color: inherit;
	font-family: ${props => props.theme.fonts.big};
`
const FooterText = styled(HTMLContent)`
	a {
		color: white;

		&:hover {
			text-decoration: underline;
		}
	}
`

export default Footer
