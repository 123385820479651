import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

const UniversalLink = ({
	children,
	to,
	state,
	activeClassName,
	isDropdown,
	dropdownItems,
	partiallyActive,
	external = false,
	...other
}) => {
	// Tailor the following test to your environment.
	// This example assumes that any internal link (intended for Gatsby) will start with exactly one slash, and that anything else is external.

	if (external) {
		return (
			<a href={to} {...other} target="_blank" rel="noopener noreferrer">
				{children}
			</a>
		)
	}
	const internal = /^\/(?!\/)/.test(to)
	// Use Gatsby Link for internal links, and <a> for others
	if (internal) {
		return (
			<GatsbyLink to={to} activeClassName={activeClassName} partiallyActive={partiallyActive} {...other}>
				{children}
			</GatsbyLink>
		)
	}
	return (
		<a href={to} {...other} target="_blank" rel="noopener noreferrer">
			{children}
		</a>
	)
}
export default UniversalLink
