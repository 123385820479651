import React from 'react'
import styled from 'styled-components'
import MenuItems from '../MenuItems'

const DesktopMenu = () => {
	return (
		<MenuLinksContainer>
			<MenuItems lineThickness={'0.2rem'} />
		</MenuLinksContainer>
	)
}

const MenuLinksContainer = styled.nav`
	ul {
		display: flex;
		margin: 0;
	}

	li {
		display: flex;
		align-items: center;
		list-style-type: none;
		margin: 0;
	}

	a {
		margin-right: 1.3rem;
	}
`

export default DesktopMenu
