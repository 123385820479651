import { useStaticQuery, graphql } from 'gatsby'

export const useMenuItems = () => {
	const data = useStaticQuery(
		graphql`
			query {
				wpMenu(locations: { eq: GATSBY_HEADER_MENU }) {
					id
					name
					menuItems {
						nodes {
							id
							label
							cssClasses
							childItems {
								nodes {
									url
									label
								}
							}
							connectedNode {
								node {
									uri
								}
							}
						}
					}
				}
			}
		`
	)
	return data.wpMenu.menuItems.nodes.map(menuItem => {
		return {
			id: menuItem.id,
			label: menuItem.label,
			cssClasses: menuItem.cssClasses,
			childItems: menuItem.childItems.nodes,
			uri: menuItem.connectedNode.node.uri
		}
	})
}
