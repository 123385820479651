import React from 'react'
import styled from 'styled-components'
import { useGeneralSettings } from '../../../hooks/useGeneralSettings'

const BottomBar = () => {
	const { title } = useGeneralSettings()

	return (
		<BottomBarContainer>
			<BottomBarText>
				© {new Date().getFullYear()} - {title}
			</BottomBarText>
		</BottomBarContainer>
	)
}

const BottomBarContainer = styled.footer`
	width: 100%;
	padding: 1rem;
	background-color: #000000;
`
const BottomBarText = styled.div`
	color: #fff;
	font-size: 1rem;
`

export default BottomBar
