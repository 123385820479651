import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'

import Icon from '../../Icon/Icon'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import ButtonComponent from '../../Button/Button'
import Switch from '../../Switch/Switch'
import { isMobileOnly } from 'react-device-detect'

const PreferenceModal = ({ closeHandler, open, declineAllHandler, savePrefHandler }) => {
	const [modalIsOpen, setIsOpen] = useState()
	const [selectedPreferences, setSelectedPreferences] = useState(['NECESSARY', 'ADS', 'STATISTICS'])

	const buttonPadding = isMobileOnly ? '16px 90px' : '16px 140px'

	const styles = {
		overlay: {
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: 'rgb(47 47 47 / 75%)',
			zIndex: 99999,
			overflowY: 'auto',
			paddingTop: 10
		},

		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			borderRadius: 8,
			padding: 0,
			maxWidth: 740,
			width: '100%',
			border: 'none'
		}
	}

	useEffect(() => {
		setIsOpen(open)
	}, [open])

	const handleCloseModal = () => {
		setIsOpen(false)
		closeHandler()
	}

	const toggleSelectedPreferences = pref => {
		if (selectedPreferences.includes(pref)) {
			setSelectedPreferences(selectedPreferences.filter(item => item !== pref))
			return
		}

		setSelectedPreferences([...selectedPreferences, pref])
	}

	const preferences = [
		{
			type: 'NECESSARY',
			title: 'Noodzakelijk',
			text: 'Noodzakelijke cookies worden gebruikt om de kernfunctionaliteit van de website te waarborgen, zoals het onthouden van inloggegevens en winkelwageninhoud. Door gebruik te maken van deze website stemt u in met het gebruik van deze cookies voor essentiële doeleinden.'
		},
		{
			type: 'ADS',
			title: 'Advertenties',
			text: 'Advertentiecookies worden gebruikt om advertenties weer te geven die relevant zijn voor uw interesses, gebaseerd op uw surfgedrag en voorkeuren. Door het accepteren van deze cookies kunnen wij en onze partners advertenties afstemmen op uw behoeften en interesses.'
		},
		{
			type: 'STATISTICS',
			title: 'Statistieken',
			text: "Statistische cookies worden gebruikt om anonieme gegevens te verzamelen over het gebruik van de website, zoals het aantal bezoekers en de bezochte pagina's. Deze informatie helpt ons de prestaties van de website te verbeteren. Door deze cookies te accepteren, helpt u ons om onze website te optimaliseren en te blijven verbeteren."
		}
	]

	return (
		<Modal style={styles} isOpen={modalIsOpen}>
			<ModalContainer>
				<ModalHeader>
					Pas hier uw privacy voorkeuren aan{' '}
					<CloseButton onClick={handleCloseModal}>
						<Icon width="1.2rem" height="1.2rem" color="#fff" margin="0 0 0 5px" icon={faTimes} />
					</CloseButton>
				</ModalHeader>
				<ModalContent>
					{preferences.map((preference, key) => {
						return (
							<Preference key={preference.type}>
								<PreferenceTitle>{preference.title}</PreferenceTitle>
								<PreferenceContent>
									<PreferenceText>{preference.text}</PreferenceText>
									<Switch
										onChange={() => {
											toggleSelectedPreferences(preference.type)
										}}
										checked={selectedPreferences.find(pref => pref === preference.type)}
									/>
								</PreferenceContent>
							</Preference>
						)
					})}
				</ModalContent>
				<ModalFooter>
					<ButtonComponent
						variant="tertiary"
						clickHandler={() => declineAllHandler()}
						padding="16px 80px"
						text="Alles afwijzen"
					/>
					<ButtonComponent
						variant="primary"
						clickHandler={() => savePrefHandler(selectedPreferences)}
						padding={buttonPadding}
						text="Sla voorkeuren op"
					/>
				</ModalFooter>
			</ModalContainer>
		</Modal>
	)
}
export default PreferenceModal

const ModalFooter = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 20px;
	flex-direction: column;
	gap: 10px;

	@media (min-width: ${props => props.theme.sizes.maxTablet}) {
		flex-direction: row;
		gap: 0;
	}
`

const PreferenceText = styled.span``

const PreferenceTitle = styled.p`
	font-weight: bold;
	margin-bottom: 5px;
`

const PreferenceContent = styled.div`
	display: flex;
	align-items: center;
	gap: 40px;
`

const Preference = styled.div`
	display: flex;
	flex-direction: column;
`

const CloseButton = styled.div`
	height: 20px;
	cursor: pointer;

	&:hover {
		i {
			cursor: pointer;
			color: grey;
		}
	}
`

const ModalContent = styled.div`
	background-color: white;
	padding: 20px;
	display: flex;
	gap: 40px;
	flex-direction: column;
`

const ModalHeader = styled.div`
	background-color: #656565;
	height: 75px;
	display: flex;
	align-items: center;
	color: white;
	padding: 20px;
	justify-content: space-between;
`

const ModalContainer = styled.div`
	height: 100dvh;

	@media (min-width: ${props => props.theme.sizes.maxTablet}) {
		height: auto;
	}
`
