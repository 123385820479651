import React from 'react'
import styled from 'styled-components'
import DesktopMenu from '../Menu/DesktopMenu'
import MobileMenu from '../Menu/MobileMenu'

const Menu = () => {
	return (
		<>
			<DesktopMenuWrapper>
				<DesktopMenu />
			</DesktopMenuWrapper>
			<MobileMenuWrapper>
				<MobileMenu />
			</MobileMenuWrapper>
		</>
	)
}

const DesktopMenuWrapper = styled.div`
	display: show;

	@media (max-width: ${props => props.theme.sizes.maxDesktop}) {
		display: none;
	}
`

const MobileMenuWrapper = styled.div`
	display: show;

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		display: none;
	}
`

export default Menu
