import React from 'react'
import styled from 'styled-components'

const CTASlider = ({ icon, link, click, content, position, name }) => {
	return (
		<CTASliderContainer position={position}>
			<CTASliderIconWrapper>
				<CTASliderIcon src={icon} alt={name} />
			</CTASliderIconWrapper>
			<CTASliderLink onClick={click} href={link} target="_blank">
				{content}
			</CTASliderLink>
		</CTASliderContainer>
	)
}
export default CTASlider

const CTASliderContainer = styled.div`
	right: -10rem;
	position: fixed;
	margin-top: ${props => props.position};
	z-index: 9996;
	color: #707070;
	display: flex;
	transition: right ease 0.55s;
	align-items: center;

	&:hover {
		right: 0;
	}
`
const CTASliderIconWrapper = styled.span`
	font-size: 20px;
	background-color: #656565;
	border-radius: 10px 0 0px 10px;

	min-height: 58px;
	display: flex;
	align-items: center;
	padding: 12px;
`
const CTASliderIcon = styled.img`
	width: 30px;
`

const CTASliderLink = styled.a`
	background-color: #eee;
	color: #333333;
	padding: 16px 8px;
`
