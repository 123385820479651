import React from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'
import Menu from './Menu'
import { useOptionsPage } from '../../../hooks/useOptionsPage'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Nav = () => {
	const optionsPageData = useOptionsPage()
	const logoImage = getImage(optionsPageData.logo.localFile)

	return (
		<NavContainer>
			<LogoWrapper href="/">
				<GatsbyImage image={logoImage} alt={'logo'} />
			</LogoWrapper>
			<MenuWrapper>
				<Menu />
			</MenuWrapper>
		</NavContainer>
	)
}

const NavContainer = styled.header`
	position: fixed;
	top: 30px;
	left: 0;
	width: 100%;
	height: 6rem;
	z-index: 1000;
	background-color: ${transparentize(0.4, 'black')};
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media (max-width: ${props => props.theme.sizes.maxDesktop}) {
		top: 0;
	}
`

const LogoWrapper = styled.a`
	display: flex;
	align-items: center;
	margin-left: 2rem;
	max-width: 200px;

	${props => props.theme.sizes.maxDesktop} {
		max-width: 250px;
	}
`

const MenuWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-right: 2rem;
`

export default Nav
