import React from 'react'
import styled from 'styled-components'
import Hero from './Hero'
import Nav from './Nav'
import Topbar from './Topbar'

const Header = ({ headerData, isFrontPage }) => {
	return (
		<HeaderContainer
			height={headerData && headerData.height}
			pxOrVh={headerData && headerData.pxOrVh}
			bgColor={headerData && headerData.backgroundColor}
		>
			<Topbar />
			<Nav />
			<Hero
				isHomePage={isFrontPage}
				heroChoice={headerData && headerData.heroChoice}
				localImageFile={headerData && headerData.image?.localFile}
				videoUrl={headerData && headerData.videoUrl}
				backgroundColor={headerData && headerData.backgroundColor}
				backgroundOpacity={headerData && headerData.backgroundOpacity}
				heroText={(headerData && headerData.heroText) || ''}
			/>
		</HeaderContainer>
	)
}

const HeaderContainer = styled.header`
	position: relative;
	width: 100%;
	height: ${props => props.height + props.pxOrVh};
	background-color: ${props => props.backgroundColor};
`

export default Header
