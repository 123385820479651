import styled from 'styled-components'
import UniversalLink from '../UniversalLink/UniversalLink'

const LinkOrButtonWrapper = styled.div`
	display: inline-flex;
	align-items: center;
`

// const Anchor = styled(UniversalLink)`
// 	font-family: ${props => (props.type === 'link' ? props.theme.fonts.big : props.theme.fonts.medium)};
// 	color: ${props => (props.type === 'link' ? props.theme.colors.primary : '#fff')};
// 	background-color: ${props => (props.type === 'link' ? 'transparent' : props.theme.colors.primary)};
// 	border-radius: ${props => (props.type === 'link' ? 'unset' : '20px')};
// 	padding: ${props => (props.type === 'link' ? '0' : '10px 20px')};
// `
const Anchor = styled(UniversalLink)`
	font-family: ${props => props.theme.fonts.big};
	color: ${props => props.theme.colors.primary};
	padding: 0;
`

export { LinkOrButtonWrapper, Anchor }
