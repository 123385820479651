import React from 'react'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import { RedirectButton, OnClickButton } from './Button.styled'

import Icon from '../Icon/Icon'

const ButtonComponent = props => {
	return (
		<>
			{props.url ? (
				<RedirectButton
					margin={props.margin}
					variant={props.variant}
					to={props.url}
					state={props.state}
					padding={props.padding}
					disabled={props.disabled}
				>
					{props.text}
				</RedirectButton>
			) : (
				<OnClickButton
					margin={props.margin}
					variant={props.variant}
					onClick={props.clickHandler}
					padding={props.padding}
					disabled={props.disabled}
				>
					{props.text}
				</OnClickButton>
			)}
		</>
	)
}

export default ButtonComponent
