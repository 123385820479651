import React from 'react'
import styled from 'styled-components'
import { useOptionsPage } from '../../../hooks/useOptionsPage'
import WhatsappImage from '../../../../content/assets/svg/whatsapp-brands.svg'

const Topbar = () => {
	const optionsPageData = useOptionsPage()
	const headerItems = optionsPageData.top_header_items
	const number = optionsPageData.top_header_phone_number

	return (
		<TopbarContainer>
			<TopbarItems>
				{headerItems.map((item, i) => (
					<TopbarHeaderItem key={i} href={new URL(item.page.page_url).pathname}>
						{item.page.title}
					</TopbarHeaderItem>
				))}
				<PhoneNumber target="_blank" href="https://wa.me/31180396341">
					<WhatsappIcon src={WhatsappImage} alt="Whatsapp" />
					{number}
				</PhoneNumber>
			</TopbarItems>
		</TopbarContainer>
	)
}

export default Topbar

const WhatsappIcon = styled.img`
	width: 15px;
`

const PhoneNumber = styled.a`
	display: flex;
	align-items: center;
	gap: 10px;

	&:hover {
		color: #02679a;
	}
`

const TopbarHeaderItem = styled.a`
	color: #b4b4b4;
	font-size: 14px;

	&:hover {
		color: #7b7b7b;
	}
`

const TopbarItems = styled.div`
	margin: 0 3.5rem;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 30px;
	height: 100%;
`

const TopbarContainer = styled.div`
	height: 30px;
	position: fixed;
	left: 0;
	width: 100%;
	z-index: 1000;

	background-color: #333a3e;

	display: show;

	@media (max-width: ${props => props.theme.sizes.maxDesktop}) {
		display: none;
	}
`
